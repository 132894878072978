import React from 'react'
import { TypeAnimation } from 'react-type-animation'

function InfiniteAppDescription() {
    const data = [
        'עם תזכורות ללקוחות - אין הברזות',
        'הלקוחות קובעים תור לפי הזמינות שלך',
        'בסיום התור גבייה מיידית באשראי',
        'ניהול יומנים שונים לאנשי צוות',
        'שליחת הודעות תפוצה ללקוחות'
    ]

    return (
        <section className='mt-2.5 md:mt-0 infinite-app-description' style={{ color: '#10b981' }}>
            <TypeAnimation
                sequence={[
                    data[0],
                    3000, // Waits 3s
                    data[1],
                    3000,
                    data[2],
                    3000,
                    data[3],
                    3000
                ]}
                wrapper="span"
                cursor={false}
                repeat={Infinity}
                deletionSpeed={70}
                style={{ display: 'inline-block', fontWeight: 'bold' }}
            />
        </section>
    )
}

export default InfiniteAppDescription
